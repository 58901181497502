import { post } from '@/utils/request'

//添加店铺
export function addStore(data) {
    return post('/room/Shop/addShop', data)
}

// 店铺列表
export function getStoreList(data) {
    return post('/room/Shop/list', data)
}
// 店铺详情
export function getInfo(data) {
    return post('/room/Shop/info', data)
}
// 编辑店铺
export function editStore(data) {
    return post('/room/Shop/edit', data)
}
// 删除店铺
export function delStore(data) {
    return post('/room/Shop/delete', data)
}