<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>店铺管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="8">
              <el-button type="primary" @click="pAdd()">添加店铺</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            v-loading="tableLoading"
            class="task-table"
            :data="tableData"
          >
            <el-table-column prop="shop_name" label="店铺名"> </el-table-column>
            <el-table-column label="店铺图">
              <template v-slot="scope">
                <img :src="scope.row.pic" width="100" alt="" />
              </template>
            </el-table-column>
            <el-table-column label="省市区">
              <template v-slot="scope">
                {{ scope.row.area_class1_name }} -
                {{ scope.row.area_class2_name }} -
                {{ scope.row.area_class3_name }}
              </template>
            </el-table-column>
            <el-table-column prop="address" label="具体地址"> </el-table-column>
            <el-table-column prop="class_status" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.status === 1">
                  启用
                </el-tag>
                <el-tag type="warning" v-if="scope.row.status === 0">
                  禁用
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="desc" label="店铺描述"> </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="handleEdit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  @click="remove(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            :pager-count="15"
            @current-change="pageChange"
            @size-change="sizeChange"
            :page-sizes="[7, 15, 20]"
            layout="total, sizes, prev, pager, next"
            :page-size="this.query.limit"
            background
            :total="exp.total"
          >
          </el-pagination>
        </div>
      </div>
    </el-card>
    <!-- 添加/删除对话框 -->
    <el-dialog
      v-dialogDrag
      :title="title"
      :visible.sync="dialogVisible"
      :before-close="formClose"
    >
      <el-form :rules="formRules" ref="formRef" :model="form">
        <el-form-item
          prop="shop_name"
          label="店铺名称"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.shop_name"
            placeholder="请填写店铺名称"
          ></el-input>
        </el-form-item>

        <el-form-item prop="pic" label="店铺图" :label-width="formLabelWidth">
          <el-upload
            accept=".jpg,.png,.jpeg"
            :limit="1"
            :action="imgUrl"
            list-type="picture"
            :on-exceed="exceed"
            name="image"
            :file-list="imgList"
            :on-change="getFile"
          >
            <el-button size="small" type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item
          prop="area_id"
          label="省市区"
          :label-width="formLabelWidth"
        >
          <el-cascader
            style="width:100%"
            :options="provList"
            :props="optionProps"
            v-model="form.area_id"
            @change="handleChange"
            filterable
            clearable
          >
          </el-cascader>
        </el-form-item>
        <el-form-item
          prop="address"
          label="详细地址"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.address"
            placeholder="请填写详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="longitude"
          label="经度"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.longitude"
            placeholder="请填写经度"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="latitude"
          label="纬度"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.latitude"
            placeholder="请填写纬度"
          ></el-input>
        </el-form-item>
        <el-form-item
          prop="desc"
          label="店铺描述"
          :label-width="formLabelWidth"
        >
          <el-input
            @change="handleChange"
            v-model="form.desc"
            placeholder="请填写店铺描述"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="addOrEdit === 2"
          prop="status"
          label="状态"
          :label-width="formLabelWidth"
        >
          <el-switch
            @change="handleChange"
            v-model="form.status"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ff4949"
          />
        </el-form-item>
      </el-form>
      <!-- 功能栏 -->
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelAE()">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="confirmAE()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import lodash from 'lodash'

import {
  getSiteServiceTypeList,
  addSiteServiceType,
  editSiteServiceType,
  delSiteServiceType,
} from '@/api/edu/siteservicetype.js'
import {
  addStore,
  getStoreList,
  getInfo,
  editStore,
  delStore,
} from '@/api/room/store.js'
import { provinceList } from '@/api/edu/location.js'
export default {
  data() {
    return {
      provList: [],
      imgList: [],
      isEdit: false,
      tableLoading: false,
      warningText: '',
      exp: {
        total: 0,
      },
      btnLoading: false,
      spcateList: [],
      serviceList: [],
      query: {
        page: 1,
        page_code: '',
        limit: 7,
      },
      optionProps: {
        checkStrictly: false,
        value: 'id',
        label: 'cname',
        children: 'sub',
      },
      dialogVisible: false,
      changeDialogVisible: false,
      formRules: {
        shop_name: {
          required: true,
          message: '请填写店铺名称',
          trigger: 'blur',
        },
        pic: {
          required: true,
          message: '请上传店铺图片',
          trigger: 'blur',
        },
        area_id: {
          required: true,
          message: '请选择省市区',
          trigger: 'blur',
        },
        address: {
          required: true,
          message: '请填写详细地址',
          trigger: 'blur',
        },
        longitude: {
          required: true,
          message: '请填写经度',
          trigger: 'blur',
        },
        latitude: {
          required: true,
          message: '请填写纬度',
          trigger: 'blur',
        },
        desc: {
          required: true,
          message: '请填写店铺描述',
          trigger: 'blur',
        },
      },
      title: '添加站点服务类型',
      addOrEdit: 1,
      formLabelWidth: '160px',
      optionsTask: [],
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      form: {
        shop_name: '',
        pic: '',
        area_id: '',
        address: '',
        longitude: '',
        latitude: '',
        desc: '',
        status: '',
      },
      tableData: [],
      provList: [],
    }
  },
  created() {
    this.getList()
    this.getOptionList()
  },
  computed: {
    imgUrl() {
      return this.$store.state.imgUrl
    },
  },
  methods: {
    async getOptionList() {
      const { data: res } = await provinceList()
      console.log(res)
      if (res.code === 1) {
        this.provList = res.data
      } else {
        this.$message.error('获取省市区数据失败！')
      }
    },
    //文件转base64
    getBase64(file) {
      return new Promise(function(resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function() {
          imgResult = reader.result
        }
        reader.onerror = function(error) {
          reject(error)
        }
        reader.onloadend = function() {
          resolve(imgResult)
        }
      })
    },
    getFile(file, fileList) {
      //  console.log('plan change')
      this.isEdit = true
      // this.editPlan = true
      if (this.beforeAvatarUpload(file)) {
        this.getBase64(file.raw).then((res) => {
          //     console.log(res)
          this.form.pic = res
          //   console.log(this.form.plan_pic)

          // this.ruleForm.imagePath = res
        })
      }
    },
    beforeAvatarUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 5MB!')
      }
      return isLt5M
    },
    exceed() {
      this.$message({
        message: '只能上传一张图片',
        type: 'warning',
      })
    },
    pageChange(val) {
      this.query.page = val
      this.getList()
    },
    sizeChange(val) {
      this.query.limit = val
      this.getList()
    },
    async getList() {
      this.tableLoading = true
      const { data: res } = await getStoreList(this.query)
      console.log(res)
      if (res.code === 1) {
        this.tableData = res.data.data
        this.exp.total = res.data.total
      } else {
        this.$message.error('获取数据失败！')
      }
      this.tableLoading = false
    },
    // 添加/编辑商品对话框关闭事件
    formClose() {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    handleChange() {
      this.isEdit = true
    },
    handleChangeClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          this.changeDialogVisible = false
          this.getList()
          done()
        })
        .catch((_) => {})
    },
    // 取消
    cancelAE() {
      this.title = '添加店铺'
      this.addOrEdit = 1
      this.isEdit = false
      this.form = {
        shop_name: '',
        pic: '',
        area_id: '',
        address: '',
        longitude: '',
        latitude: '',
        desc: '',
        status: '',
      }
      this.imgList = []
      if (this.$refs.formRef) {
        setTimeout(() => {
          this.$refs.formRef.clearValidate()
        }, 30)
      }

      this.dialogVisible = false
    },

    // 新增打开
    pAdd(row) {
      console.log(row)

      this.dialogVisible = true
    },
    // 编辑打开
    async handleEdit(row) {
      console.log(row)
      this.dialogVisible = true
      this.title = '编辑店铺'
      this.addOrEdit = 2
      const { data: res } = await getInfo({
        shop_id: row.shop_id,
      })
      console.log(res.data)
      //   this.form.class_name = res.data.class_name
      //   this.form.class_status = res.data.class_status
      this.form.shop_id = res.data.shop_id
      this.form.address = res.data.address
      this.form.desc = res.data.desc
      this.form.shop_name = res.data.shop_name
      this.form.latitude = res.data.latitude
      this.form.longitude = res.data.longitude
      this.form.pic = res.data.pic
      this.imgList.push({ url: res.data.pic })
      this.form.status = res.data.status
      this.form.area_id = [
        res.data.area_class1,
        res.data.area_class2,
        res.data.area_class3,
      ]
    },

    // 删除
    remove(row) {
      this.$confirm('确认删除？')
        .then(async (_) => {
          const { data: res } = await delStore({
            shop_id: row.shop_id,
          })
          if (res.code === 1) {
            this.$message.success('删除成功！')
            this.getList()
          } else {
            this.$message.error(res.message)
          }
          this.cancelAE()
          // this.getDepartmentList()
          done()
        })
        .catch((_) => {})
    },
    confirmAE(e) {
      console.log(e)
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          if (this.addOrEdit === 1) {
            // 添加
            let query = lodash.cloneDeep(this.form)
            query.area_id = query.area_id[query.area_id.length - 1]
            var formData = new FormData()
            formData.append('shop_name', query.shop_name)
            formData.append('pic', query.pic)
            formData.append('area_id', query.area_id)
            formData.append('address', query.address)
            formData.append('longitude', query.longitude)
            formData.append('latitude', query.latitude)
            formData.append('desc', query.desc)
            this.btnLoading = true
            const { data: res } = await addStore(formData)
            if (res.code === 1) {
              this.$message.success('添加成功')
              this.getList()
              this.cancelAE()
            } else {
              this.$message.error(res.message)
            }
            this.btnLoading = false
          } else {
            // 编辑

            let query = lodash.cloneDeep(this.form)
            query.area_id = query.area_id[query.area_id.length - 1]
            var formData = new FormData()
            formData.append('shop_name', query.shop_name)
            formData.append('shop_id', query.shop_id)
            formData.append('pic', query.pic)
            formData.append('status', query.status)
            formData.append('area_id', query.area_id)
            formData.append('address', query.address)
            formData.append('longitude', query.longitude)
            formData.append('latitude', query.latitude)
            formData.append('desc', query.desc)
            this.btnLoading = true
            if (this.isEdit === true) {
              const { data: res } = await editStore(formData)
              if (res.code === 1) {
                this.$message.success('编辑成功')
                this.cancelAE()
                this.getList()
              } else {
                this.$message.error(res.message)
              }
            } else {
              this.$message.info('没有修改任何数据')
            }
            this.btnLoading = false
          }
        }
      })
    },
  },
}
</script>

<style scoped>
.content {
  display: flex;
  align-items: center;
}
.content .warning-icon {
  width: 44px;
  height: 32px;
  margin-right: 10px;
  background: url('../../../assets/exclamationmark.png') no-repeat;
}
</style>
